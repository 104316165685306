<template>
  <div class="workbook-result">
    <workbook-result from="workbook" :doubleLine="false" />
  </div>
</template>

<script>
import WorkbookResult from '@/components/workbook/WorkbookResult.vue'
import { mapActions } from 'vuex'
export default {
  name: 'WorkbookResults',
  components: { WorkbookResult },
  created() {
    if (!this.$route.params.forReview)
      this.fetchUserWorkbook(this.$route.params.id)
  },
  methods: {
    ...mapActions('workbook', ['fetchUserWorkbook']),
  },
}
</script>

<style></style>
